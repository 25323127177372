import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TooltipService {
  private viewContainterRef?: ViewContainerRef;

  setViewContainerRef(viewContainterRef: any) {
    this.viewContainterRef = viewContainterRef;
  }

  async toggle(id: string, template: TemplateRef<any>) {
    if (!this.viewContainterRef) {
      console.error(
        'A view container reference is required to create a tooltip. Typically this is provided by the app component.'
      );
      return;
    }
    const tooltipComponent = await import(
      '../components/tooltip/tooltip.component'
    ).then((module) => module.TooltipComponent);
    this.viewContainterRef.clear();
    const tooltipComponentRef =
      this.viewContainterRef.createComponent(tooltipComponent);
    tooltipComponentRef.setInput('id', id);
    tooltipComponentRef.setInput('template', template);
    setTimeout(() => {
      tooltipComponentRef.instance.popover.nativeElement.toggle();
      tooltipComponentRef.instance.popover.nativeElement.addEventListener(
        'closed',
        () => this.viewContainterRef?.clear(),
        {
          once: true,
        }
      );
    });
  }
}
